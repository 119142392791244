import { DataStore } from 'aws-amplify/datastore';

import AuthService from '@/domain/auth/AuthService';
import useUserStore from '@/domain/user/state/useUserStore';
import { User } from '@/models';
import { getTableUserByUserId } from '@/services/chatUnitUser/API/getChatUnitUserByUserId';
import { updateChatUnitUser } from '@/services/datastore/user/updateChatUnitUser';
import { updateUser as datastoreUpdateUser } from '@/services/datastore/user/updateUser';
import { factory } from '@/services/factory/factory';
import { logger } from '@/services/logger/logger';
import { createUser } from '@/services/user';

type UserInput = {
  avatar: string;
  birthday?: string;
  name: string;
  pronouns?: string;
  username: string;
  phoneNumber?: string;
  marketingOptIn?: boolean;
};

export const upsertUser = async (input: UserInput) => {
  const cognitoUser = await AuthService.getCognitoUser();
  let userExists = false;
  if (cognitoUser?.userId) {
    const dbUser = await DataStore.query(User, cognitoUser?.userId);
    userExists = !!dbUser;
  }
  try {
    const id = cognitoUser?.userId;
    if (!id) {
      throw new Error('cognitoUser.userId is required');
    }
    // chat users can be created prior to inviting new user, so need to update for new users also
    const chatUsers = await getTableUserByUserId(id);
    await Promise.all(
      chatUsers.map(chatUser => {
        return updateChatUnitUser(chatUser.chatUnitUserId, {
          avatar: input.avatar,
          username: input.username,
          ...(input.phoneNumber !== undefined && {
            phoneNumber: input.phoneNumber,
          }),
        });
      }),
    );

    if (userExists) {
      const updatedUser = await datastoreUpdateUser({
        id,
        ...input,
      });
      useUserStore.getState().setUser(updatedUser);
    } else {
      const phoneNumber = cognitoUser?.signInDetails?.loginId;
      if (!phoneNumber) {
        throw new Error(
          'cognitoUser.phoneNumber is required to create new user',
        );
      }
      const newUser = await createUser({
        id,
        ...input,
        phoneNumber,
      });
      useUserStore.getState().setUser(factory.user(newUser));
    }
  } catch (err) {
    logger.error('ERROR::UserProvider::upsertUser', err);
  }
};
