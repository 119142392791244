import Section from '@/components/section/Section';
import { constants } from '@/constants';
import type { MainStackScreenProps } from '@/navigation/types';
import useTranslations from '@/translations/useTranslation';
import { AppText, Spacer } from '@/ui/app';
import { BorderRadius, Fonts } from '@/ui/common/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Pressable, StyleSheet, TextInput } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-controller';
import * as yup from 'yup';
import { Colors } from '../theme/Colors';

import CheckBox from '@react-native-community/checkbox';
import OnboardingAvatarPreview from './components/OnboardingAvatarPreview';
import OnboardingNextButton from './components/OnboardingNextButton';

import { ChatUnitColorId } from '@/models';
import { getAllAvatarsMap } from '@/services/assetService';
import { upsertUser } from '@/services/datastore/user/upsertUser';
import { getRandomTableColor } from '@/utilities/constants/table-colors';
import generateUniqueId from '@/utilities/helpers/uuid';
import { web } from '@/utilities/platform';
import { uploadData } from 'aws-amplify/storage';
import { useMMKVString } from 'react-native-mmkv';
import useUserStore from '../user/state/useUserStore';
import OnboardingAvatarEmptyPlaceholder from './components/OnboardingAvatarEmptyPlaceholder';
import OnboardingCreateProfileAvatarPressActions from './components/OnboardingCreateProfileAvatarPressActions';
import OnboardingCreateProfileTNC from './components/OnboardingCreateProfileTNC';
import OnboardingWebLayout from './components/OnboardingWebLayout';

type Props = MainStackScreenProps<'OnboardingCreateProfile'>;

type FormFields = {
  fullName: string;
  shortName: string;
  avatar: string;
  marketingConsent: boolean;
};

const validationSchema = yup.object({
  fullName: yup.string().label('Your name').required('Your name is required'),
  shortName: yup
    .string()
    .label('Short name')
    .required('Short name is required')
    .max(constants.displayUserNameLimit),
  avatar: yup.string().default(''),
  marketingConsent: yup.boolean().default(false),
});

const OnboardingCreateProfileScreen = ({ route, navigation }: Props) => {
  const { translate } = useTranslations();
  const userId = useUserStore(state => state.user?.id);
  const [onboardingType, _] = useMMKVString('onboardingType');
  const isBusiness = onboardingType === 'business';
  const [actionListOpen, setActionListOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    control,
    formState: { isValid },
    setValue,
    watch,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (route.params?.selectedAvatar) {
      setValue('avatar', route.params.selectedAvatar);
    }
  }, [route.params?.selectedAvatar, setValue]);

  const onSubmit = async (data: FormFields) => {
    setLoading(true);
    const input = {
      avatar: data.avatar,
      name: data.fullName,
      username: data.shortName,
      marketingOptIn: data.marketingConsent ?? false,
    };
    if (input.avatar) {
      const AVATARS_MAP = getAllAvatarsMap();
      if (!AVATARS_MAP.get(input.avatar)?.id) {
        const fileRes = await fetch(data.avatar);
        const blob = await fileRes.blob();
        let fileName = '';
        if (data.avatar.startsWith('file://')) {
          fileName = generateUniqueId().concat(data.avatar.split('/').pop()!);
        } else {
          // base64
          const fileType = data.avatar.split(';')[0].split('/')[1];
          fileName = generateUniqueId().concat('.').concat(fileType);
        }
        const imageKey = `users/${userId}/${Date.now()}-${fileName}`;
        const { key } = await uploadData({
          data: blob,
          key: imageKey,
        }).result;

        input.avatar = key;
      }
    } else {
      input.avatar = getRandomTableColor(ChatUnitColorId.BLACK);
    }

    await upsertUser(input);

    setLoading(false);
    if (isBusiness) {
      navigation.navigate('OnboardingCreateWorkspace', {
        stepNumber: 3,
        totalSteps: 4,
      });
    } else {
      navigation.navigate('OnboardingAddMembers', {
        stepNumber: 4,
        totalSteps: 4,
      });
    }
  };

  return (
    <>
      {web ? null : <Spacer width="100%" height={1} bg={Colors.neutral10} />}
      <KeyboardAwareScrollView
        contentContainerStyle={styles.container}
        showsVerticalScrollIndicator={web ? false : undefined}
        bottomOffset={20}>
        <AppText
          size={web ? 17 : 14}
          type="primary700"
          color={Colors.neutral70}>
          {translate('step_x_of_y', {
            current: route.params?.stepNumber || 2,
            total: route.params?.totalSteps || 4,
          }).toUpperCase()}
        </AppText>
        <Spacer height={10} />
        <AppText size={web ? 35 : 28} type="primary800">
          {translate('create_account_title')}
        </AppText>
        <Spacer height={40} />
        <Section row={web} jcsb={web} aifs={web} gap={web ? 20 : undefined}>
          <Section f1>
            <AppText size={web ? 17 : 14} type="primary700">
              {translate('your_name')}
            </AppText>
            <Spacer height={16} />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              name="fullName"
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  style={styles.nameInput}
                  textAlign="center"
                  verticalAlign="middle"
                />
              )}
            />
          </Section>
          <Spacer height={web ? 0 : 40} />
          <Section f1>
            <AppText size={web ? 17 : 14} type="primary700">
              {translate('short_nickname')}
            </AppText>
            <Spacer height={16} />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              name="shortName"
              render={({ field: { onChange, onBlur, value } }) => (
                <Section f1>
                  <TextInput
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    style={styles.nameInput}
                    textAlign="center"
                    verticalAlign="middle"
                    maxLength={constants.displayUserNameLimit}
                  />
                  <AppText
                    size={11}
                    type="primary600"
                    color={
                      (value || '').length > constants.displayUserNameLimit
                        ? Colors.rasberry
                        : Colors.peppermint
                    }
                    style={styles.countText}>
                    {(value || '').length}/{constants.displayUserNameLimit}
                  </AppText>
                </Section>
              )}
            />
            <Spacer height={10} />
            <AppText size={web ? 14 : 12} color={Colors.neutral70}>
              {translate('short_nickname_subtitle')}
            </AppText>
          </Section>
        </Section>

        <Spacer height={40} />
        <Section row gap={30}>
          <Section f1>
            <AppText size={web ? 17 : 14} type="primary700">
              {translate('add_profile_photo_text')}
              {'\n'}
              <AppText size={web ? 17 : 14}>{`(${translate(
                'optional',
              )})`}</AppText>
            </AppText>
            <Spacer height={15} />
            <Pressable onPress={() => setActionListOpen(true)}>
              <OnboardingAvatarEmptyPlaceholder />
            </Pressable>
          </Section>
          <Section f1>
            <AppText size={web ? 17 : 14} type="primary700">
              {translate('your_8seats_preview')}
              {'\n'}
              <AppText size={web ? 17 : 14} color={'transparent' as Colors}>
                Placeholder text
              </AppText>
            </AppText>
            <Spacer height={15} />
            <OnboardingAvatarPreview
              avatar={watch('avatar')}
              shortName={watch('shortName')}
              fullName={watch('fullName')}
            />
            <Spacer height={15} />
            <AppText size={web ? 14 : 12} color={Colors.neutral70}>
              {translate('your_8seats_preview_subtitle')}
            </AppText>
          </Section>
        </Section>
        <Spacer height={40} />
        <Section row jcsb gap={15}>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <CheckBox
                boxType="square"
                onCheckColor={Colors.primaryLight}
                onFillColor={Colors.neutral0}
                onTintColor={Colors.primaryLight}
                onValueChange={onChange}
                style={styles.check}
                value={value}
              />
            )}
            name="marketingConsent"
          />
          <AppText size={web ? 17 : 14} style={styles.overflowText}>
            {translate('updates_text_sms')}
          </AppText>
        </Section>
        <Spacer height={40} />
        <OnboardingCreateProfileTNC />
        <Spacer height={40} />
        <OnboardingNextButton
          loading={loading}
          onPress={handleSubmit(onSubmit)}
          text={translate('create_my_account')}
          disabled={!isValid}
        />
        <Spacer height={60} />
        <Controller
          control={control}
          name="avatar"
          render={({ field: { onChange, value } }) => (
            <OnboardingCreateProfileAvatarPressActions
              avatar={value}
              onSelectAvatar={onChange}
              open={actionListOpen}
              setOpen={setActionListOpen}
            />
          )}
        />
      </KeyboardAwareScrollView>
    </>
  );
};

const OnboardingCreateProfileScreenWeb = (props: Props) => {
  return (
    <OnboardingWebLayout showBackButton>
      <OnboardingCreateProfileScreen {...props} />
    </OnboardingWebLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.neutral0,
    paddingHorizontal: 30,
    paddingTop: 50,
    flexGrow: 1,
  },
  nameInput: {
    fontSize: 17,
    color: Colors.neutral80,
    fontFamily: Fonts.primary400,
    height: 50,
    borderWidth: 1,
    borderRadius: BorderRadius.sm,
    borderColor: Colors.neutral30,
    paddingLeft: web ? 10 : undefined,
    ...(web ? { outlineStyle: 'none' } : {}),
    flexGrow: 1,
  },
  check: {
    width: 24,
    height: 24,
    borderColor: Colors.neutral50,
  },
  overflowText: {
    width: 0,
    flexGrow: 1,
  },
  countText: {
    position: 'absolute',
    right: 6,
    bottom: 6,
    letterSpacing: 1.1,
  },
});

export default web
  ? OnboardingCreateProfileScreenWeb
  : OnboardingCreateProfileScreen;
