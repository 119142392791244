import { validateInput } from './validateInput';

import type { User } from '@/services/chatUnit/types';

export const userFactory = (input: any): User => {
  validateInput(input, ['id'], 'User');

  return {
    avatar: input.avatar ?? 'Bob',
    birthday: input.birthday,
    createdAt: input.createdAt,
    id: input.id,
    name: input.name ?? 'Unknown User',
    owner: input.owner,
    phoneNumber: input.phoneNumber,
    pronouns: input.pronouns,
    updatedAt: input.updatedAt,
    username: input.username ?? 'Unknown User',
    organisations:
      input?.organisations?.map((org: string) => JSON.parse(org)) ?? [],
  };
};
