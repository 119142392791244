export default {
  "ENV": "dev",
  "ENV_URL": "dev",
  "FETCH_BACKOFF_ATTEMPT_COUNT": "3",
  "LOG_DATASTORE_EVENTS": "true",
  "LOG_LEVEL": "0",
  "LOG_RESTRICT_TO_USERNAMES": "",
  "POSTHOG_API_KEY": "phc_WmmaRnqe9zHP3QmwA6qV53l3vZz7b9aZMGJqCi3Wcvp",
  "POSTHOG_DEBUG": "",
  "ROOMS_LIMIT": "1000",
  "ROOM_USER_LIMIT": "100",
  "TABLES_LIMIT": "1000",
  "CMS_URL": "https://graphql.contentful.com/content/v1/spaces/rrch712do7ep",
  "CMS_TOKEN": "cQcS2XOBgmc7xj-42jD1EQOtS6ZvHh6Jfv23c_ERbPg"
};